import React, { useEffect } from 'react'
import Layout from '../components/layout.tr'
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Counter from '../components/counter'
import AgileAnim from '../components/agileAnim.tr'
import Subscribe from '../components/subscribe'
import MemberCard from '../components/memberCard'
import PageHeader from '../components/pageHeader'
import members from "../data/team-members.json"
import LinkToForm from '../components/linkToForm.tr'
import ContactUsForm from '../components/contact-us.tr'

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");

export default () => {
  useEffect(() => {

    // Fade Up 5 (Footer blocks)
    var fadeUp5 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp5
      .add({
        targets: ['.about-hero-header', '.lottie-world-map', '.intro1'],
        translateY: [50, 0],
        translateZ: 0,
        opacity: [1, 1],
        easing: "easeOutExpo",
        duration: 900,
        delay: (el, i) => 50 + 80 * i
      });


    // Fade Up 5 (Product Preview)
    var fadeUp6 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp6
      .add({
        targets: ['.centered-h2', '.value-block'],
        translateY: [25, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 50 + 80 * i
      });



    // Play animation when scrolled into view
    $('.about-hero').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp5.play();
        fadeUp5.restart();
      } else {
      }
    });

    // Play animation when scrolled into view
    $('.values').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp6.play();
        fadeUp6.restart();
      } else {
      }
    });

  });
  return (
    <Layout currentPath='/tr/hakkimizda/' light={true} fullPage={true}>
      <SEO title="Hakkımızda" />
      <div className="about-hero">
        <h1 className="about-hero-header">Sizin memnuniyetiniz <br /><span className="colored-span">bizim en büyük hedefimiz</span></h1>
        <div className="map-container">
          <div className="lottie-world-map">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={"https://assets7.lottiefiles.com/packages/lf20_9wtujxng.json"}
            ></lottie-player>
          </div>
        </div>
      </div>
      <div className="intro wf-section">
        <div className="intro-container">
          <div className="intro1 first">
            <h2 className="intro-header green">Şirketimiz</h2>
            <p className="intro-paragraph">2020 yılında kurulan şirketimiz, Teknopark İstanbul 'da yer almaktadır. Gücümüzü mutlu müşterilerimizden alarak büyüyoruz. Uzman kadromuz ile kurulduğumuz günden itibaren müşterilerimizin teknolojik ihtiyaçlarını en doğru şekilde analiz ederek katma değerli hizmet vermek için çalışmaktayız. </p>
          </div>
          <div className="intro1">
            <h2 className="intro-header orange">Hizmetlerimiz</h2>
            <p className="intro-paragraph">Fiboo, kurumların "dijital dönüşüm" süreçlerine yönelik çözüm ve ürün geliştiren teknoloji şirketidir. Microsoft tabanlı ve yapay zeka destekli kurum içi iletişim platformu Moodly ile dijital dönüşüm sürecinizi hızlandırmayı sağlamaktayız. </p>
          </div>
        </div>
      </div>
      <div className="values wf-section">
        <h2 className="centered-h2">
          <span className="text-span-15">Değerlerimiz</span>
        </h2>
        <div className="value-contaiiner">
          <div className="value-block">
            <div className="value-text-block">
              <h3 className="heading-16">Tutkulu</h3>
              <p className="paragraph-6">Hedeflerimiz doğrultusunda globalde de yer almak için her geçen gün daha fazla tutku ile çalışıyoruz. Hedefe giden yolda karşılaştığımız sorunların ekip olarak üstesinden gelerek, mutlu müşterilerimizden güç alırız.</p>
            </div>
          </div>
          <div className="value-block">
            <div className="value-text-block">
              <h3 className="heading-16">Çözüm Odaklı</h3>
              <p className="paragraph-6">Müşterilerimizin ihtiyaçlarını en iyi şekilde analiz ederek kendi alanında nitelikli ekibimiz ile çözümler üretiriz.</p>
            </div>
          </div>
          <div className="value-block">
            <div className="value-text-block">
              <h3 className="heading-16">İnovatif</h3>
              <p className="paragraph-6">Müşterilerimizin ihtiyaçları doğrultusunda, teknolojik gelişmeleri takip ederek kendimizi geliştirir ve en uygun çözümü sunarız.</p>
            </div>
          </div>
          <div className="value-block">
            <div className="value-text-block">
              <h3 className="heading-16">Nitelikli</h3>
              <p className="paragraph-6">Her zaman daha iyisi için kendi alanında uzman profesyoneller ile çalışırız. İşlerimizin kalitesine önem vererek kendimizi daha çok sorgular ve en iyisini hedefleriz.</p>
            </div>
          </div>
          <div className="value-block">
            <div className="value-text-block">
              <h3 className="heading-16">Takım Çalışması</h3>
              <p className="paragraph-6">"Takım çalışması, hedeflerin gerçekleşmesini sağlar." düşüncesiyle ekibimiz, şirketimizin en önemli parçasıdır.</p>
            </div>
          </div>
        </div>
      </div>
      <ContactUsForm />
    </Layout>
  );
}